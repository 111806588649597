<template>
  <div>
    <div :style="{height: contentHeight + 'px'}" style="overflow-y: auto; overflow-x: hidden">
      <v-form ref="form">
        <v-row>
          <v-col cols="6" class="p-content">
            <div class="p-title asterisk-div">基础信息</div>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    :rules="[v => !!v || '请输入商品名称']"
                    label="商品名称"
                    v-model="form.description"
                    class="asterisk"/>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    :rules="[v => !!v || '请输入商品价格']"
                    label="商品价格"
                    v-model="form.showprice"
                    class="asterisk"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    v-model="form.area"
                    label="发货地区"/>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="form.seq"
                    label="排序号码"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <div class="p-title">发货方式</div>
                <v-radio-group v-model="form.sendtype">
                  <v-radio
                      label="快递发货"
                      :value="0"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6">
                <div class="p-title">运费算法</div>
                <v-radio-group v-model="form.posttype" row>
                  <v-radio
                      label="包邮"
                      :value="0"
                  ></v-radio>
                  <v-radio
                      label="根据地区计算运费"
                      :value="1"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <div class="p-title asterisk-div mb-2">商品主图</div>
            <material
                :required="true"
                sub-title="点击添加按钮新增上传区域，拖动选中的文件区域可对其排序，图片建议：宽高比例4:3，大小不能超过2MB，格式仅限于jpg、png、jpeg、webp，数量上限为6张"
                :limit="5"
                :value-image="form.mlistpic"
                @change="form.mlistpic = $event"/>

            <div class="p-title mt-6">商品参数</div>
            <div v-if="form.paramkey" class="d-flex flex-wrap">
              <v-col cols="6" v-for="(item, index) in form.paramkey.split(',')" :key="index">
                <v-text-field
                    :value="form.param ? form.param.split(',')[index]: ''"
                    :label='item'
                    @input="changeParam(index, $event)"
                />
              </v-col>
            </div>
          </v-col>
          <v-col cols="6" class="p-content">
            <div class="p-title">商品详情</div>
            <div class="mt-2" style="font-size: 12px; color: #999999">
              您只需要维护一份商品详情数据，将同时在电脑端和移动端生效，无需拆分维护。图片规格:
              2M以下的图片(jpg/png)，建议尺寸宽638像素，高不限制。
            </div>
            <editor-text
                class="mt-5"
                @change="form.richtext = $event"
                :value="form.richtext"/>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div v-if="!edit">
      <v-divider/>
      <div class="text-center pa-4">
        <v-btn depressed color="primary" class="mr-4" outlined @click="$emit('changeStep', 1)">上一步</v-btn>
        <v-btn depressed color="primary" @click="nextStep">下一步</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import material from "@/components/common-material/material.vue";
import editorText from '@/components/editorText/editorText.vue';

export default {
  data() {
    return {
      contentHeight: 0,
      form: {
        mlistpic: '',
        richtext: '',
        description: '',
        showprice: '',
        area: '',
        seq: 0,
        sendtype: 0,
        posttype: 0,
        param: '',
        paramkey: ''
      }
    }
  },
  props: {
    code: String,
    p_form: {
      type: Object,
      default: () => ({})
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    material,
    editorText
  },
  mounted() {
    this.contentHeight = document.documentElement.clientHeight - 56 - 24 - 72 - (this.edit ? 48 : 99)
  },
  methods: {
    nextStep() {
      if (this.$refs.form.validate()) {
        this.$emit('changeStep', 3)
      }
    },
    changeParam(index, value) {
      let res = []
      this.form.param.split(',').forEach((v, i) => {
        res.push(i === index ? value : v)
      })
      this.form.param = res.join(',')
    }
  },
  watch: {
    code: {
      handler(v) {
        if (v) {
          this.axios.post('/api/gift/load_gift', {searchkey: v}).then(res => {
            // this.form.param = res.data.tags
            this.form.paramkey = res.data.tags
            if (!this.edit) {
              let p = ''
              let array = res.data.tags.split(',');
              array.forEach((t, i)=> {
                if (i !== array.length - 1)
                p += ','
              })
              this.form.param = p
            }
          })
        }
      },
      immediate: true
    },
    p_form: {
      handler(v) {
        if (v && JSON.stringify(v) !== '{}') {
          this.form = v
        }
      },
      deep: true,
      immediate: true
    },
    form: {
      handler(v) {
        if (v && JSON.stringify(v) !== '{}') {
          this.$emit('change', v)
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.p-title {
  font-size: 18px;
  color: #666666;
  font-weight: bold;
}

.p-content {
  padding: 32px 40px;
}
</style>
