<template>
  <div>
    <common-table
        :headers="headers"
        :listData="listData"
        :loading="loading"
        ref="ct"
        :rightConfig="rightConfig"
        :leftConfig="leftConfig"
        :fixedHeight="fixedHeight"
        :normalPage="false"
        @search="search"
        :showPagination=false
        @otherRightOperate="otherRightOperate"
        @saveTableFiled="saveTableFiled"
    />
    <template v-if="batchDialog">
      <form-menu
          :center="true"
          :center_btn="true"
          :listDeploy="listDeploy"
          height="255px"
          width="408px"
          :ridDivision="true"
          :dialog.sync="batchDialog"
          rightbtn_text="确定"
          leftbtn_text="取消"
          @resetForm="batchDialog = false"
          @validateSave="validateSave"
          ref="formRef"
          :title="batchTitle"/>
    </template>
  </div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from "@/api/productManage/hand-greeting/storePrice";
import formMenu from "@/components/form-menu/form-menu.vue";

export default {
  mixins: [commonCurd],
  components: {
    formMenu
  },
  props: {
    searchKey: String,
    specs1: String,
    specs2: String
  },
  data() {
    return {
      api,
      headers: [],
      leftConfig: [
        {type: 'input', label: '请输入规格描述', property: 'specs'}
      ],
      rightConfig: [
        {label: '批量价格', icon: '', event: 'batchPrice'},
        {label: '批量库存', icon: '', event: 'batchStore'}
      ],
      batchDialog: false,
      batchTitle: '',
      listDeploy: {},
      fixedHeight: 300
    }
  },
  watch: {
    specs1: {
      handler(v) {
        if (v) {
          this.headers = [
            {text: this.specs1, value: 'specs1'},
            {text: this.specs2, value: 'specs2'},
            {text: '售卖价格', value: 'rate', editable: true},
            {text: '剩余库存', value: 'avl', editable: true}
          ]
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.fixedHeight = document.documentElement.clientHeight - 56 - 24 - 99 - 192
  },
  methods: {
    otherRightOperate(event) {
      this.batchDialog = true;
      if (event === this.rightConfig[0].event) {
        this.batchTitle = this.rightConfig[0].label;
        this.listDeploy = {
          row: [
            {
              cols: 12,
              subassembly: "VTextField",
              property: 'rate',
              connector: {
                props: {
                  label: '售卖价格',
                  required: true
                }
              }
            }
          ]
        }
      } else if (event === this.rightConfig[1].event) {
        this.batchTitle = this.rightConfig[1].label;
        this.listDeploy = {
          row: [
            {
              cols: 12,
              subassembly: "VTextField",
              property: 'avl',
              connector: {
                props: {
                  label: '剩余库存',
                  required: true
                }
              }
            }
          ]
        }
      }
    },
    saveTableFiled(field, value, item) {
      this.axios.post(this.api.save, item).then(r => {
        // this.list();
      });
    },
    createdExtend() {
      this.fixedParams.searchKey = this.searchKey
    },
    validateSave(item, callback) {
      if (item.done) {
        item.form.giftitem = this.searchKey
        item.form.specs = this.$refs.ct.$refs.leftBtn.form.specs
        this.axios.post(this.api.save, item.form).then(r => {
          if (r.code === this.staticVal.Code.Success) {
            this.snackbar.success("保存成功");
            callback();
            this.list();
          } else {
            this.$refs.formRef.load = false;
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
