<template>
  <div>
    <div :style="{height: contentHeight + 'px'}" style="overflow-y: auto; overflow-x: hidden">
      <v-row>
        <v-col cols="6" class="p-content">
          <div class="asterisk-div p-title mb-4">销售信息</div>
          <div class="mt-2">
            <material
                :title="specs1"
                sub-title="图片建议：宽高比例4:3，大小不能超过2MB，格式仅限于jpg、png、jpeg、webp"
                :label="specs1"
                :has-input="true"
                :valueText="form.specs1"
                :value-image="form.specs1pic"
                @change="changeMaterial"/>
          </div>
          <div class="mt-6">
            <category-template-props
                :title="specs2"
                :col="4"
                :value="form.specs2"
                @change="form.specs2 = $event"/>
          </div>
        </v-col>
        <v-col cols="6" class="p-content" v-if="form.code">
          <div class="p-title">销售规格</div>
          <div class="mt-4">
            <sales-spec-table
                :specs1="specs1"
                :specs2="specs2"
                :search-key="form.code"/>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="!edit">
      <v-divider/>
      <div class="text-center pa-4">
        <v-btn depressed outlined color="primary" class="mr-4" @click="$emit('changeStep', 2)">上一步</v-btn>
        <v-btn depressed color="primary" @click="saveSend" :loading="loading">保存并发布</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import material from "@/components/common-material/material.vue";
import categoryTemplateProps from "../category-template-props.vue"
import salesSpecTable from "./sales-spec-table.vue";

export default {
  name: "sales-specification",
  props: {
    code: String,
    p_form: {
      type: Object,
      default: () => ({})
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    material,
    categoryTemplateProps,
    salesSpecTable
  },
  data() {
    return {
      contentHeight: 0,
      form: {
        // 图片
        specs1pic: '',
        // 颜色
        specs1: '',
        // 尺码
        specs2: ''
      },
      loading: false,
      specs1: '',
      specs2: ''
    }
  },
  mounted() {
    this.contentHeight = document.documentElement.clientHeight - 56 - 24 - 72 - (this.edit ? 48 : 99)
  },
  methods: {
    saveSend() {
      if (!this.form.specs1) {
        this.snackbar.error('请输入' + this.specs2)
      }
      if (!this.form.specs1pic) {
        this.snackbar.error('请选择图片')
      }
      if (this.form.specs1 && this.form.specs1pic) {
        this.loading = true
        this.$emit('changeStep', null)
      }
    },
    changeMaterial(image, text) {
      this.form.specs1pic = image
      this.form.specs1 = text
    }
  },
  watch: {
    p_form: {
      handler(v) {
        if (v && JSON.stringify(v) !== '{}') {
          this.form = v
        }
      },
      deep: true,
      immediate: true
    },
    form: {
      handler(v) {
        if (v && JSON.stringify(v) !== '{}') {
          this.$emit('change', v)
        }
      },
      immediate: true,
      deep: true
    },
    code: {
      handler(v) {
        if (v) {
          this.axios.post('/api/gift/load_gift', {searchkey: v}).then(res => {
            this.specs1 = res.data.specs1
            this.specs2 = res.data.specs2
          })
        }
      },
      immediate: true
    },
  }
}
</script>

<style scoped lang="scss">
.p-title {
  font-size: 18px;
  color: #666666;
  font-weight: bold;
}

.p-sub-title {
  font-size: 14px;
  color: #666666;
  margin: 16px 0;
  font-weight: bold;
}

.p-content {
  padding: 32px 40px;
}
</style>
